<template>
    <div class="container">
        <div class="navigationMargin">
            <div class="backTo">
                <router-link to="/home" class="noLine">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="long-arrow-alt-left"
                         class="arrowIcon svg-inline--fa fa-long-arrow-alt-left fa-w-14" role="img"
                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path fill="currentColor"
                              d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z"></path>
                    </svg>
                    <span>SETTINGS</span>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times"
                         class="xIcon svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 352 512">
                        <path fill="currentColor"
                              d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
                    </svg>
                </router-link>
            </div>

            <div class="listSettings">
                <ul>
                    <li>
                        <router-link :to="{name: 'settings.profile'}" class="menuLink">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                 width="24" height="24" viewBox="0 0 24 24">
                                <defs>
                                    <clipPath id="clip-My_profile">
                                        <rect width="24" height="24"/>
                                    </clipPath>
                                </defs>
                                <g id="My_profile" data-name="My profile" clip-path="url(#clip-My_profile)">
                                    <g id="Group_892" data-name="Group 892" transform="translate(-37.309 -91)">
                                        <path id="Path_1279" data-name="Path 1279"
                                              d="M30.306,609.036l1.853-2.075h5.189l1.8,2.075v3.421l-1.8,2.195H32.159l-1.853-2.195Z"
                                              transform="translate(14.798 -515.059)" fill="#707070"/>
                                        <path id="Path_1280" data-name="Path 1280"
                                              d="M30.306,610.817l3.443-3.856h9.641l3.352,3.856v6.356H30.306Z"
                                              transform="translate(11.003 -503.542)" fill="#707070"/>
                                    </g>
                                </g>
                            </svg>

                            My Profile
                        </router-link>

                    </li>
                    <li>
                        <router-link to="/chat" class="noLine">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                 width="24" height="24" viewBox="0 0 24 24">
                                <defs>
                                    <clipPath id="clip-Instant_messages">
                                        <rect width="24" height="24"/>
                                    </clipPath>
                                </defs>
                                <g id="Instant_messages" data-name="Instant messages"
                                   clip-path="url(#clip-Instant_messages)">
                                    <path id="Path_1283" data-name="Path 1283"
                                          d="M445.023-12.45h-7.842l-6.012,4.9-.147,10.515-4.145,2.664h18.146l5.243-4.379v-8.8ZM441.8.789h-2V-1.044h2Zm0-3.666h-2v-5.5h2Z"
                                          transform="translate(-426.877 15.45)" fill="#707070"/>
                                </g>
                            </svg>

                            Instant messages
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'settings.privacy'}" class="menuLink">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                 width="24"
                                 height="24" viewBox="0 0 24 24">
                                <defs>
                                    <clipPath id="clip-Privacy">
                                        <rect width="24" height="24"/>
                                    </clipPath>
                                </defs>
                                <g id="Privacy" clip-path="url(#clip-Privacy)">
                                    <path id="Path_1284" data-name="Path 1284"
                                          d="M511.979-3.809V-8.782l-1.734-3.558h-7.611l-1.822,3.547v4.984h-1.858v13h15v-13Zm-9.167-4.5,1.043-2.031h5.14l.984,2.02v4.511h-7.167Zm4.937,10.517h-.932V5.469h-.932V2.208h-.931V-.587h2.795Z"
                                          transform="translate(-493.954 13.34)" fill="#707070"/>
                                </g>
                            </svg>

                            Privacy
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'settings.TermsAndConditions'}" class="menuLink">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                 width="24"
                                 height="24" viewBox="0 0 24 24">
                                <defs>
                                    <clipPath id="clip-Terms_Conditions">
                                        <rect width="24" height="24"/>
                                    </clipPath>
                                </defs>
                                <g id="Terms_Conditions" data-name="Terms &amp; Conditions"
                                   clip-path="url(#clip-Terms_Conditions)">
                                    <g id="Group_903" data-name="Group 903" transform="translate(-526.308 18.141)">
                                        <path id="Path_1285" data-name="Path 1285" d="M542.021-16.269V-11H547.3Z"
                                              fill="#707070"/>
                                        <path id="Path_1286" data-name="Path 1286"
                                              d="M541.021-10v-7.145H530.308v21h17V-10Zm-7.212,2.86h6v1h-6Zm10,7h-10v-1h10Zm0-3h-10v-1h10Z"
                                              fill="#707070"/>
                                    </g>
                                </g>
                            </svg>

                            Terms &#38; Conditions
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'settings.Support'}" class="menuLink">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                 width="24"
                                 height="24" viewBox="0 0 24 24">
                                <defs>
                                    <clipPath id="clip-Support">
                                        <rect width="24" height="24"/>
                                    </clipPath>
                                </defs>
                                <g id="Support" clip-path="url(#clip-Support)">
                                    <path id="Path_1280" data-name="Path 1280"
                                          d="M-2755.8,9656.3l4.134,4.134-4.065,4.064-4.065-4.064-.4,4.191,4.044,4.045,5.209-1.26,9.289,9.289,2.421-2.422-9.443-9.442,1.369-5.011-4.045-4.045Z"
                                          transform="translate(2762.198 -9654.317)" fill="#707070"/>
                                </g>
                            </svg>

                            Support
                        </router-link>
                    </li>
                    <li>
                        <a href="#" class="noLine" @click="logMeOut">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sign-out-alt"
                                 class="littleRight svg-inline--fa fa-sign-out-alt fa-w-16" role="img"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path fill="currentColor"
                                      d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"></path>
                            </svg>

                            Logout
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        async logMeOut() {
            await this.$router.push({
                name: 'SignIn',
            });
            this.$store.dispatch('logout');
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables.scss";

.navigationMargin {
    @media screen and (min-width: 768px) {
        margin-left: 45px;
    }
}

.backTo {
    padding-top: 30px;

    .noLine {
        text-decoration: none;
        text-align: left;

        .arrowIcon {
            height: 20px;
            color: $grey-text-color;
            margin-right: 20px;
        }

        .xIcon {
            display: none;
        }

        span {
            color: $grey-text-color;
            font-weight: 500;
        }

        @media screen and (min-width: 768px) {
            box-shadow: 3px 3px 6px #e8e8e8;
            padding: 12px 40px;
            width: 280px;
            text-align: left;
            display: flex;
            justify-content: space-between;

            .arrowIcon {
                display: none
            }

            .xIcon {
                height: 20px;
                color: $grey-text-color;
                margin-left: 20px;
                margin-top: 4px;
                display: block;
            }
        }
    }
}

.listSettings {
    padding-top: 40px;
    font-size: 18px;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;

        li {
            width: 100%;

            .noLine {
                text-decoration: none;
                color: $body-color;
                width: 100%;
                display: block;
                padding: 20px 30px;
            }

            .menuLink {
                text-decoration: none;
                color: $body-color;
                padding: 20px 30px;
                width: 100%;
                display: block;
            }

            svg {
                height: 20px;
                margin-right: 20px;
            }

            .littleRight {
                margin-left: 6px;
            }

            &:hover {
                border: 1px solid $secondary;
            }
        }
    }
}
</style>
